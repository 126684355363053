import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect: '/home/index',
    children: [
      {
        path: '/home/index',
        name: 'index',
        component: () => import('@/views/HomePage/Index.vue')
      },
      {
        path: '/home/dirver',
        name: 'driver',
        component: () => import('@/views/HomePage/driver.vue')
      },
      {
        path: '/home/car',
        name: 'car',
        component: () => import('@/views/HomePage/car.vue')
      },
      {
        path: '/home/phone',
        name: 'phone',
        component: () => import('@/views/HomePage/phone.vue')
      },
      {
        path: '/home/goodsRelease',
        name: 'goodsRelease',
        component: () => import('@/views/HomePage/goodsRelease.vue')
      },
      {
        path: '/home/addressManage',
        name: 'addressManage',
        component: () => import('@/views/HomePage/addressManage.vue')
      },
      {
        path: '/home/messagePromotion',
        name: 'messagePromotion',
        component: () => import('@/views/HomePage/messagePromotion/index.vue'),
        children: [
          {
            path: '/home/messagePromotion/messagePromotion',
            name: 'messagePromotion',
            component: () => import('@/views/HomePage/messagePromotion/messagePromotion.vue')
          },
          {
            path: '/home/messagePromotion/sendMessage',
            name: 'sendMessage',
            component: () => import('@/views/HomePage/messagePromotion/sendMessage.vue')
          }
        ]
      },
      {
        path: '/home/logPage',
        name: 'addressManage',
        component: () => import('@/views/HomePage/logPage.vue')
      },
      {
        name: "用户版本更新",
        path: '/home/userVersion',
        component: () => import('@/views/HomePage/userVersion.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
