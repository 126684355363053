<template>
    <div class="main">
        <div class="header-title">
            中昊数智货运业务管理平台
        </div>
        <div class="login-container">
            <h2 class="title">登 录</h2>
            <el-form class="login-form" :model="loginForm" ref="loginForm" label-width="80px">
                <el-form-item label="用户名">
                    <el-input v-model="loginForm.phone" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input type="password" v-model="loginForm.password" placeholder="请输入密码"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="login-button" type="primary" @click="login">登录</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>

</template>


<script>
import { userLogin } from '@/api/login'
import { setLoginState } from '@/utils/request'
export default {
    data() {
        return {
            loginForm: {
                phone: '',
                password: ""
            }
        }
    },
    mounted() {
    },
    methods: {
        async login() {
            // 在此处编写登录逻辑
            if (!this.loginForm.phone || !this.loginForm.password) {
                this.$message({
                    message: '信息不完整',
                    type: 'error'
                });
                return;
            }


            let result = await userLogin(this.loginForm);
            if (result.code == 200) {
                //登陆成功
                this.$message({
                    message: result.msg,
                    type: 'success'
                });
                //存储登陆状态
                setLoginState(result.data);
                this.$router.push({
                    path: '/home/index',
                    query: { showIndex: true }
                })
            } else {
                this.$message({
                    message: result.msg,
                    type: 'error'
                });
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.main {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url(@/assets/bg.webp) no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .header-title {
        position: absolute;
        margin-top: 30px;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 10%;
        margin: auto;
        font-size: 40px;
        font-weight: 700;
        color: #fff;
        letter-spacing: 0.1em;
        user-select: none;
    }

    .login-container {
        width: 400px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
        margin-left: 40%;
    }

    .login-container .title {
        text-align: center;
    }

    .login-form {
        margin-top: 20px;
    }

    .login-button {
        margin-top: 20px;
        width: 50%;
        text-align: center;
        margin-left: 15%;
        /* width: 40%; */
    }
}
</style>