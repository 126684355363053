import qs from 'qs'
import axios from 'axios'
import router from '@/router'
import store from '@/store'
import md5 from 'md5'

// 使用element-ui Message做消息提醒
import { Message, MessageBox } from "element-ui";
import { toLogin, toAuth } from "@/utils/tool";

var baseURL = 'https://api.zhgylgl.com'

//加密平台标识
const OS = 'web';

//加密盐
const SALT = 'emhhHaC';

//上传地址
const UPLOADURL = 'https://api.zhgylgl.com/api/uploadImg';

//获取请求头
export function getHeader() {
    const timeStamp = Date.now() + '';
    const sign = '01' + md5(timeStamp + SALT);
    // const token = localStorage.getItem('zhToken') || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwaG9uZSI6IjEzMzczNzI3NjE1IiwidXNlcl9pZCI6MzU1NjcsImxldmVsIjozLCJhcHByb3ZlIjowLCJpYXQiOjE3MDk2MjA3OTJ9.U3p7HLmiwUpekvxXacKwm8jIECj3_NPVb9gxeJvqzdc';
    // const userId = localStorage.getItem('zhUserId') || '35567';
    const token = localStorage.getItem('zhToken');
    const userId = localStorage.getItem('UserId');
    return {
        'os': OS,
        'x-time': timeStamp,
        'x-sign': sign,
        'token': token,
        'userId': userId
    }
}

//初次进入判断是否存在token
export function isLogin() {
    // return false
    return !!localStorage.getItem('zhToken');
}

//获取 token方法
const getToken = () => {
    return localStorage.getItem('zhToken');
}
const getUserId = () => {
    return localStorage.getItem('UserId');

}

//登陆后设置登陆状态
export function setLoginState(info) {
    localStorage.setItem('zhUserPhone', info.phone);
    localStorage.setItem('zhToken', info.token);
    localStorage.setItem('UserId', info.user_id);
    store.commit("setUserInfo", info);
}

// 1. 创建新的axios实例，
const service = axios.create({
    // 公共接口--webpack中的全局变量process.env.BASE_API
    // 为了适应多个后台或者开发的时候的api地址和发布的时候的api地址不一样这种情况
    baseURL: baseURL,
    // 超时时间 单位是ms，这里设置了10s的超时时间
    timeout: 10 * 1000,
})


//请求拦截器
service.interceptors.request.use(
    (config) => {
        // 发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
        // config.data = JSON.stringify(config.data); // 数据转化,也可以使用qs转换
        // config.headers = {
        //   "Content-Type": "application/x-www-form-urlencoded", // 配置请求头
        // };
        //注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
        const token = getToken(); // 这里取token之前，你肯定需要先拿到token,存一下
        config.headers = getHeader();
        // config.headers['user_id'] = getUserId();
        if (['/api/business/releaseGoods', "/api/business/getUserAddress", "/api/business/delAddress"].includes(config.url)) {
            config.headers.userId = 8860
        }
        if (token) {
            //   config.params = { token: token }; // 如果要求携带在参数中
            config.headers['Token'] = token; // 如果要求携带在请求头中
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);


// 3. 响应拦截器
service.interceptors.response.use(
    (response) => {
        //接收到响应数据并成功后的一些共有的处理，关闭loading等
        if (response.data.code == 401) {
            //登录失效
            toLogin();
        }
        if (response.data.code == 402) {
            //未认证或认证不通过
            toAuth();
        }
        return response;
    },
    (error) => {
        /* ****  接收到异常响应的处理开始  **** */
        if (error && error.response) {
            // 1. 公共错误处理
            // 2. 根据响应码具体处理
            switch (error.response.status) {
                case 400:
                    error.message = "错误请求";
                    break;
                case 401:
                    error.message = "未授权，请重新登录";
                    toLogin();
                    break;
                case 403:
                    error.message = "拒绝访问";
                    break;
                case 404:
                    error.message = "请求错误,未找到该资源";
                    // window.location.href = "/NotFound";
                    break;
                case 405:
                    error.message = "请求方法未允许";
                    break;
                case 408:
                    error.message = "请求超时";
                    break;
                case 500:
                    error.message = "服务器端出错";
                    break;
                case 501:
                    error.message = "网络未实现";
                    break;
                case 502:
                    error.message = "网络错误";
                    break;
                case 503:
                    error.message = "服务不可用";
                    break;
                case 504:
                    error.message = "网络超时";
                    break;
                case 505:
                    error.message = "http版本不支持该请求";
                    break;
                default:
                    error.message = `连接错误${error.response.status}`;
            }
        } else {
            // 超时处理
            if (JSON.stringify(error).includes("timeout")) {
                Message.error("服务器响应超时，请刷新当前页");
            }
            error.message("连接服务器失败");
        }

        Message.error(error.message);

        /* ****  处理结束  **** */
        // 如果不需要错误处理，以上的处理过程都可省略
        return Promise.resolve(error.response);
    }
);


//封装请求方法
const request = (params) => {
    let option = {
        method: params.method || 'get',
        url: params.url,
        params: params.data,
        data: qs.stringify(params.data),
    }
    if (params.method == 'post') {
        delete option.params
    } else {
        delete option.data
    }
    return new Promise((resolve, reject) => {
        service(option)
            .then(res => {
                //统一拦截处理接口错误提醒
                if (typeof res == 'string') {
                    resolve(JSON.parse(res.data))
                } else {
                    resolve(res.data)
                }
            })
            .catch(err => {
                reject(err)
            })
    })

}

export default request;