import { codeToText } from "element-china-area-data";
import router from '@/router';
import store from '@/store'

// 使用element-ui Message做消息提醒
import { Message, MessageBox, Alert } from "element-ui";
//把区域码转成汉字
export const getCodeToText = (codeArray) => {
  let area = [];
  switch (codeArray.length) {
    case 1:
      area.push(codeToText[codeArray[0]])
      break;
    case 2:
      area.push(codeToText[codeArray[0]])
      area.push(codeToText[codeArray[1]])
      break;
    case 3:
      area.push(codeToText[codeArray[0]])
      area.push(codeToText[codeArray[1]])
      area.push(codeToText[codeArray[2]])
      break;
    default:
      break;
  }
  return area;
}

//判断是否认证 没有认证提示去认证页
export const toAuth = () => {

  MessageBox.confirm('未认证,请先去认证?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    router.replace({
      path: '/home/publishGoods/accountSetting/authInfo',
    }).catch(e => e)
  }).catch(() => {
  });
}

let timer = null;//设置定时器添加防抖 防止出现多个登录失效弹框
/** 
* 跳转登录页
* 携带当前页面路由，以期在登录页面完成登录后返回当前页面
*/
export const toLogin = () => {
  if (timer) return;
  timer = setTimeout(() => {
    localStorage.removeItem('zhToken');
    localStorage.removeItem('zhUserPhone');
    localStorage.removeItem('UserId');
    store.commit('setUserInfo', null);
    // store.commit('setRole', null);
    // store.commit('setAuthInfo', null);
    Message({
      showClose: true,
      message: '请先登录！',
      type: 'error'
    })
    router.replace({
      path: '/login',
    })
    clearTimeout(timer);
    timer = null;
  }, 200)
}
