<template>
    <div class="publish">
        <div class="main">
            <el-row class="tac">
                <el-col :span="3" class="left">
                    <div class="logo-name">中昊后台审核系统</div>
                    <el-menu background-color="#304156" text-color="#fff" active-text-color="#409EFF"
                        :default-active="defaultActive" :unique-opened="true" class="el-menu-vertical-demo"
                        @open="handleOpen" @close="handleClose" @select="handleSelect">
                        <!-- <el-menu-item v-for="(item, index) in menuList" :index="`${index + 1}`" :key="index"
                            v-show="!item.hidden" @click="selectPath(item.path, item.name,)">
                            <template slot="title">
                                <i :class="item.icon"></i>
                                <span>{{ item.name }}</span>
                            </template>
</el-menu-item> -->
                        <!-- :default-openeds="['1','2','3','4',]" -->
                        <template v-for="(item, index) in menuList">
                            <el-menu-item v-if="!item.child" :index="`${index + 1}`" v-show="!item.hidden"
                                @click="selectPath(item.path, item.name,)">
                                <template slot="title">
                                    <i :class="item.icon"></i>
                                    <span>{{ item.name }}</span>
                                </template>
                            </el-menu-item>
                            <el-submenu v-else :index="`${index + 1}`" v-show="!item.hidden"
                                @click="selectPath(item.path, item.name)">
                                <template slot="title">
                                    <i :class="item.icon"></i>
                                    <span>{{ item.name }}</span>
                                </template>
                                <el-menu-item-group>
                                    <el-menu-item v-for="(t, i) in item.child" :key="i" :index="`${index + 1}-${i + 1}`"
                                        @click="selectPath(t.path, item.name, t.name)">
                                        <template slot="title">
                                            <i :class="t.icon"></i>
                                            <span>{{ t.name }}</span>
                                        </template>
                                    </el-menu-item>
                                </el-menu-item-group>
                            </el-submenu>
                        </template>

                    </el-menu>
                </el-col>

                <el-col :span="21" class="right">
                    <div class="breadcrumb">
                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
                            <el-breadcrumb-item v-if="childName">{{ childName }}</el-breadcrumb-item>
                        </el-breadcrumb>
                        <div class="breadcrumb-right">
                            <el-button v-if="!userName" type="primary" @click="goLogin()">去登陆</el-button>
                            <span v-else @click="selectPath('loginOut')">
                                <i class="el-icon-user-solid"></i>
                                <span>{{ userName }}</span>
                            </span>
                        </div>
                    </div>
                    <div class="viewArea">
                        <router-view />
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
// import getMenu from "@/utils/menu";
import { isLogin, getHeader } from "@/utils/request"
import { getConfig } from '@/api/getConfig'
import { toLogin } from '@/utils/tool'
export default {
    name: "Home",
    data() {
        return {
            parentName: "首页",
            childName: "",
            defaultActive: "1",
            menuList: [
                {
                    hidden: false,
                    icon: "el-icon-s-home",
                    name: "首页",
                    value: "index",
                    path: '/home/index'
                },
            ],
            carInfo: {
                carColor: "",
                energyType: "",
                area: ""
            },
            userName: null,
            timer: null,
        };
    },
    mounted() {
        this.afterLogin();

        if (!this.$route.query.showIndex) {
            this.defaultActive = sessionStorage.getItem('keyPath') || '1';
        }
        let arr = this.defaultActive.split('-');
        if (arr.length === 1) {
            this.setBreadcrumb(this.menuList[arr[0] - 1].name, "")
        } else if (arr.length === 2) {
            this.setBreadcrumb(this.menuList[arr[0] - 1].name, this.menuList[arr[0] - 1].child[arr[1] - 1].name)
        }
    },
    watch: {
        activeIndex(newValue) {
            // if (newValur != this.$route.path) {
            // 	this.activeIndex = this.$route.path;
            // }
            // 如果使用 v-for 循环 可以取消上面注释
            console.log(newValue);
        }
    },
    methods: {
        handleSelect(keyPath) {
            sessionStorage.setItem('keyPath', keyPath);
        },
        goLogin() {
            if (this.timer) return;
            this.timer = setTimeout(() => {
                localStorage.removeItem('token');
                localStorage.removeItem('userInfo');
                localStorage.removeItem('authInfo');
                this.$store.commit('setUserInfo', null);
                this.$router.replace({
                    path: '/login',
                }).catch(e => e)
                clearTimeout(this.timer);
                this.timer = null;
            }, 200)
        },
        //登陆过后拿到相关车辆数据
        async afterLogin() {
            if (isLogin()) {
                this.userName = localStorage.getItem('zhUserPhone');
                this.initial();
                //获取加密请求头
                this.headers = getHeader();
                //获取车辆相关填报信息
                let result = await getConfig();
                if (result.code == 200) {
                    this.$store.commit("setCarInfo", {
                        carColor: result.data.colorList,
                        energyType: result.data.energyList,
                        area: result.data.province,
                        carType: result.data.carType,
                        unit: result.data.unit,
                        carLong: result.data.carLong
                    });
                }
            } else {
                toLogin();
            }
        },
        initial() {
            this.menuList = [
                {
                    hidden: false,
                    icon: "el-icon-s-home",
                    name: "首页",
                    value: "index",
                    path: '/home/index'
                },
                {
                    hidden: false,
                    icon: "el-icon-user",
                    name: "司机认证",
                    value: "driver",
                    path: '/home/dirver'
                },
                {
                    hidden: false,
                    icon: "el-icon-truck",
                    name: "车辆认证",
                    value: "car",
                    path: '/home/car'
                },
                {
                    hidden: false,
                    icon: "el-icon-phone-outline",
                    name: "手机号注册",
                    value: "phone",
                    path: '/home/phone'
                },
                {
                    hidden: false,
                    icon: "el-icon-document-copy",
                    name: "货源管理",
                    value: "goodsRelease",
                    path: '/home/goodsRelease'
                },
                {
                    hidden: false,
                    icon: "el-icon-message",
                    name: "短信推广",
                    value: "messagePromotion",
                    path: '/home/messagePromotion',
                    child: [
                        {
                            hidden: false,
                            icon: "el-icon-message",
                            name: "短信发送",
                            value: "messagePromotion",
                            path: '/home/messagePromotion/messagePromotion',
                        },
                        {
                            hidden: false,
                            icon: "el-icon-message",
                            name: "已发送",
                            value: "messagePromotion",
                            path: '/home/messagePromotion/sendMessage',
                        }
                    ]
                },
                {
                    hidden: false,
                    icon: "el-icon-s-flag",
                    name: "地址管理",
                    value: "addressManage",
                    path: '/home/addressManage'
                },
                {
                    hidden: false,
                    icon: "el-icon-s-operation",
                    name: "日志查询",
                    value: "addressManage",
                    path: '/home/logPage'
                },
                {
                    hidden: false,
                    icon: "el-icon-s-operation",
                    name: "用户版本更新",
                    value: "addressManage",
                    path: '/home/userVersion'
                },

            ];
        },
        setBreadcrumb(pName, cName) {
            this.parentName = pName;
            this.childName = cName;
        },
        selectPath(path, pName, cName) {
            // console.log(path, this.$route.path);
            if (path == "loginOut") {
                this.$confirm("确定要退出登录吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        localStorage.removeItem("zhToken");
                        localStorage.removeItem("zhUserPhone");
                        localStorage.removeItem("zhUserId");
                        this.$store.commit("setUserInfo", null);
                        this.$router.replace({
                            path: "/login"
                        });
                        this.$message({
                            type: "success",
                            message: "退出登录成功!"
                        });
                    })
                    .catch(() => { });
                return;
            }
            this.setBreadcrumb(pName, cName);
            if (path == this.$route.path) {
                this.$router.go(0)
            } else {
                this.$router
                    .push({
                        path
                    })
                    .catch(e => e);
            }

        },
        handleOpen(key, keyPath) {
            // console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            // console.log(key, keyPath);
        }
    }
};
</script>

<style lang="scss" scoped>
.publish {
    width: 100%;
    height: 100vh;

    .main {
        width: 100%;
        margin: 0 auto;

        .tac {
            display: flex;
            flex-direction: row;
            min-height: calc(100vh);
            background: #fff;

            ::v-deep .el-menu {
                border-right: none;
            }

            ::v-deep .el-menu-item-group__title {
                padding: 0;
            }

            .left {
                min-width: 200px;
                height: 100vh;
                background-color: #304156;

                .logo-name {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 60px;
                    color: #fff;
                }
            }

            .right {
                flex: 1;
                background-color: #f5f5f5;

                .breadcrumb {
                    width: 100%;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-left: 20px;
                    box-shadow: 0 10px 10px -10px #999;
                    background-color: #fff;

                    .breadcrumb-right {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 160px;
                        height: 100%;

                        &>span {
                            cursor: pointer;
                        }
                    }
                }
            }

            .viewArea {
                overflow: auto;
                width: 100%;
                height: calc(100vh - $header-height);
                padding: 10px;
            }
        }
    }
}
</style>