import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    setUserInfo: null,//当前用户登录信息
    carInfo: null,//数据库车辆信息
  },
  getters: {
  },
  mutations: {
    setUserInfo(state, value) {
      state.userInfo = value
    },
    setCarInfo(state, value) {
      state.carInfo = value
    },
  },
  actions: {
  },
  modules: {
  }
})
